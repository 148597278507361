<template>
  <div class="blocks">

    <section class="block">
      <section>
        <h3 v-text="title"></h3>
      </section>
      <section class="form">
        <component v-bind:is=" get_component_name( form_field.type )" v-for="( form_field, index ) in form_fields" :key="form_field.name" v-bind:items="form_field.choices"
                   v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="form_field.value" v-bind:info="form_field.info"
                   v-on:changed="update_field" v-bind:store="'coshh_personal_details[' + index + ']'"
        ></component>
      </section>
      <section class="button_row">
        <a href="#" role="button" class="primary" v-bind:class="{ loading: is_loading }" @click.prevent="save_step" v-text="next_step_text"></a>
      </section>
    </section>

  </div>
</template>

<script>
import Form_Summary from "@/components/Form_Summary";
import Input_Number from "@/components/Input_Number";
import Input_Textarea from "@/components/Input_Textarea";
import Input_Text from "@/components/Input_Text";
import Input_Select from "@/components/Input_Select";
import Input_Date from "@/components/Input_Date";
import Input_Radio from "@/components/Input_Radio";
import store from "@/store/store";

export default {
  name: "Personal_Details",
  components: { Form_Summary, Input_Number, Input_Textarea, Input_Text, Input_Select, Input_Date, Input_Radio },
  data: function() {
    return {
      is_loading: false,
      title: store.state.personal_details_texts.title,
      form_fields: store.state.coshh_personal_details
    }
  },
  metaInfo: {
    title: 'Personal Details'
  },
  computed: {
    next_step_text: function()  {

      if( this.is_loading )  {

        return 'Saving...';

      }

      return 'Continue';

    }
  },
  methods: {

    toggle_loading: function()  {

      if( this.is_loading ) {

        this.is_loading = false;

      } else  {

        this.is_loading = true;

      }

    },
    save_step: function() {


      if( this.is_loading ) {

        return;

      }

      this.toggle_loading();

      store.dispatch( 'submit' ).then( result => {
// vrat mi novy token v ktorom bude post id
// uloz novy token
        // zmen akciu z new na edit (ak je new)
        console.log( result );
        this.toggle_loading();
        store.commit( 'update_submission', result.data.refresh_token );
        // go next
        this.$router.push( { name: 'basic_information' } );

      } );

      return false;

    },
    update_field: function( payload )  {

      store.commit( 'update_field', payload )

    }

  },
  mounted() {

    store.commit( 'set_step', 0 );

  }
}
</script>

<style scoped>

</style>