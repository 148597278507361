<template>
  <div class="blocks">

    <section class="block">
      <section>
        <h3 v-text="title"></h3>
      </section>
      <section class="form">
        <component v-bind:is=" get_component_name( form_field.type )" v-for="( form_field, index ) in form_fields" :key="form_field.name" v-bind:items="form_field.choices" v-bind:checked_items="form_field.checked_items"
                   v-bind:message="form_field.html"
                   v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="form_field.value" v-bind:info="form_field.info"
                   v-on:changed="update_field" v-on:remove_item="remove_item" v-bind:store="'coshh_hazards[' + index + ']'" @update_item="update_item"
        ></component>
      </section>
      <section class="button_row">
        <a href="#" role="button" class="primary" v-bind:class="{ loading: is_loading }" @click.prevent="save_step" v-text="next_step_text"></a>
        <router-link v-bind:to="{ name: 'basic_information' }" role="button">Back</router-link>
      </section>
    </section>

  </div>
</template>

<script>
import Form_Summary from "@/components/Form_Summary";
import Input_Number from "@/components/Input_Number";
import Input_Textarea from "@/components/Input_Textarea";
import Input_Text from "@/components/Input_Text";
import Input_Select from "@/components/Input_Select";
import Input_Date from "@/components/Input_Date";
import Input_Radio from "@/components/Input_Radio";
import store from "@/store/store";
import Input_Checkbox from "@/components/Input_Checkbox";
import Input_Toggle from "@/components/Input_Toggle";
import Input_Message from "@/components/Input_Message";

export default {
  name: "Hazards",
  components: { Form_Summary, Input_Number, Input_Textarea, Input_Text, Input_Select, Input_Date, Input_Radio, Input_Checkbox, Input_Toggle, Input_Message },
  data: function() {
    return {
      is_loading: false,
      title: store.state.hazards_texts.title,
      form_fields: store.state.coshh_hazards
    }
  },
  metaInfo: {
    title: 'Hazards'
  },
  computed: {
    next_step_text: function()  {

      if( this.is_loading )  {

        return 'Saving...';

      }

      return 'Continue';

    }
  },
  methods: {

    toggle_loading: function()  {

      if( this.is_loading ) {

        this.is_loading = false;

      } else  {

        this.is_loading = true;

      }

    },
    save_step: function() {


      if( this.is_loading ) {

        return;

      }

      this.toggle_loading();

      store.dispatch( 'submit' ).then( result => {
// vrat mi novy token v ktorom bude post id
// uloz novy token
        // zmen akciu z new na edit (ak je new)
        console.log( result );
        this.toggle_loading();
        store.commit( 'update_submission', result.data.refresh_token );
        // go next
        this.$router.push( { name: 'storage' } );

      } );

      return false;

    },
    update_field: function( payload )  {

      store.commit( 'update_field', payload )

    },
    update_item : function( item )  {

      store.commit( 'update_checkbox_choice', item );

    },
    remove_item : function( payload )  {

      store.commit( 'remove_checkbox_choice', payload );

    },

  },
  mounted() {

    store.commit( 'set_step', 2 );

  }
}
</script>