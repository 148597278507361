import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import axios from 'axios';
import Finish from "@/pages/Finish";
import Error_Page from "@/pages/Error_Page";
import store from "@/store/store";
import VueLocalStorage from "vue-localstorage";
import Meta from 'vue-meta'
import Personal_Details from "@/pages/Personal_Details";
import Basic_Information from "@/pages/Basic_Information";
import Hazards from "@/pages/Hazards";
import Environmental_Hazards from "@/pages/Environmental_Hazards";
import Other from "@/pages/Other";
import Fire from "@/pages/Fire";
import First_Aid from "@/pages/First_Aid";
import Using_The_Product from "@/pages/Using_The_Product";
import Moving_Handling from "@/pages/Moving_Handling";
import Storage from "@/pages/Storage";

Vue.config.productionTip = false
Vue.config.API_URL = 'https://qms.oneren.org/wp-json/tuna/api/coshh?t=';
Vue.config.API_UPLOAD_URL = 'https://qms.oneren.org/wp-json/tuna/api/upload?t=';
// Vue.config.API_URL = 'http://localhost:86/index.php/wp-json/tuna/api/coshh?t=';
// Vue.config.API_UPLOAD_URL = 'http://localhost:86/index.php/wp-json/tuna/api/upload?t=';

Vue.prototype.$http = axios;

Vue.use( VueLocalStorage );
Vue.use( VueRouter );
Vue.use( Meta );
Vue.mixin( {
  methods: {

    get_select_label: function( field ) {

      for( let i = 0; i < field.options.length; i++ ) {

        if( field.options[ i ].value === field.value )  {

          return field.options[ i ].label;

        }

      }

      return null;

    },
    get_selected_radio_label: function( field )  {

      for( let i = 0; i < field.choices.length; i++ ) {

        if( field.choices[ i ].value === field.value )  {

          return field.choices[ i ].label;

        }

      }

    },
    get_selected_checkbox_labels: function( field ) {

      let result = [];

      for( let i = 0; i < field.checked_items.length; i++ ) {

        for( let n = 0; n < field.choices.length; n++ ) {

          const type = field.choices[ n ].type;
          let prop = field.choices[ n ].value; // checkbox types are compared as values
          // if( type === 'text' ) {

            // prop = field.choices[ n ].id; // text types are compared for id

          // }
// console.log(field.choices[n].value + '==' + field.checked_items[i ] + ' ('+ type + ', ' + prop +')');
          if( prop === field.checked_items[ i ] )  {

            if( type === 'text' ) {

              // text values don't have labels
              result.push( field.choices[ n ].value );

            } else if( type === 'checkbox' )  {

              result.push( field.choices[ n ].label );

            }

          }

        }

      }

      return result.join( ', ' );

    },
    get_component_name: function( type )  {

      if( type === 'steps' )  {

        return 'Sub_Steps';

      }

      return 'Input_' + type.charAt(0).toUpperCase() + type.slice( 1 );

    }

  }

} );

const routes = [{
  path: '/',
  name: 'personal_details',
  component: Personal_Details
}, {
  path: '/basic_information',
  name: 'basic_information',
  component: Basic_Information
}, {
  path: '/hazards',
  name: 'hazards',
  component: Hazards
},{
  path: '/storage',
  name: 'storage',
  component: Storage
},{
  path: '/moving_handling',
  name: 'moving_handling',
  component: Moving_Handling
},{
  path: '/using_the_product',
  name: 'using_the_product',
  component: Using_The_Product
},{
  path: '/first_aid',
  name: 'first_aid',
  component: First_Aid
},{
  path: '/fire',
  name: 'fire',
  component: Fire
},{
  path: '/environmental_hazards',
  name: 'environmental_hazards',
  component: Environmental_Hazards
},{
  path: '/other',
  name: 'other',
  component: Other
},{
  path: '/finish',
  name: 'finish',
  component: Finish
},{
  path: '/error',
  name: 'error',
  component: Error_Page
}]

export const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach( async (to, from, next )=>{

  if( !store.state.data_loaded && to.name !== 'error' )  {

    let token = Vue.localStorage.get( 'token', null );

    if( typeof to.query.t !== 'undefined' ) {

      token = to.query.t;

    }

    await store.dispatch( 'init', {
      token: token
    } );

    next();

  } else  {

    next();

  }

});

new Vue({
  render: h => h(App),
  router: router,
}).$mount('#app');
