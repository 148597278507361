<template>

  <section class="block">
    <section>
      <h3 v-text="title"></h3>
    </section>
    <section class="form">
      <component v-bind:is=" get_component_name( form_field.type )" v-for="( form_field, index ) in form_fields" :key="form_field.name" v-bind:items="form_field.choices"
                 v-bind:options="form_field.options" v-bind:label="form_field.label" v-bind:name="form_field.name" v-bind:value="get_form_field_value( form_field )" v-bind:info="form_field.info"
                 v-on:changed="update_field" v-bind:store="'coshh_basic_information[' + index + ']'"
                 v-bind:file="get_form_field_file( form_field )" v-bind:file_field="form_field" v-bind:index="index" v-on:uploaded="update_field"
      ></component>
    </section>
    <section class="button_row">
      <a href="#" role="button" class="primary" v-bind:class="{ loading: is_loading }" @click.prevent="save_step" v-text="next_step_text"></a>
      <router-link v-bind:to="{ name: 'personal_details' }" role="button">Back</router-link>
    </section>
  </section>

</template>

<script>
import Form_Summary from "@/components/Form_Summary";
import Input_Number from "@/components/Input_Number";
import Input_Textarea from "@/components/Input_Textarea";
import Input_Text from "@/components/Input_Text";
import Input_Select from "@/components/Input_Select";
import Input_Date from "@/components/Input_Date";
import Input_Radio from "@/components/Input_Radio";
import store from "@/store/store";
import Input_File from "@/components/Input_File";
// form_field.file = value, file_field - definicia
export default {
  name: "Basic_Information",
  components: { Form_Summary, Input_Number, Input_Textarea, Input_Text, Input_Select, Input_Date, Input_Radio, Input_File },
  data: function() {
    return {
      is_loading: false,
      title: store.state.basic_information_texts.title,
      form_fields: store.state.coshh_basic_information
    }
  },
  metaInfo: {
    title: 'Basic Information'
  },
  computed: {
    next_step_text: function()  {

      if( this.is_loading )  {

        return 'Saving...';

      }

      return 'Continue';

    }
  },
  methods: {

    get_form_field_value: function( form_field )  {

      if( form_field.type !== 'file' ) {

        return form_field.value;

      }

      if( typeof form_field.value === "undefined" ) {

        return false;

      }

      return {
        file: false,
        file_name: '',
      };

    },
    get_form_field_file: function( form_field ) {

      if( typeof form_field.value === "undefined" ) {

        return {
          file: false,
          file_name: '',
        }

      }

      return form_field.value;

    },
    toggle_loading: function()  {

      if( this.is_loading ) {

        this.is_loading = false;

      } else  {

        this.is_loading = true;

      }

    },
    save_step: function() {


      if( this.is_loading ) {

        return;

      }

      this.toggle_loading();

      store.dispatch( 'submit' ).then( result => {
// vrat mi novy token v ktorom bude post id
// uloz novy token
        // zmen akciu z new na edit (ak je new)
        console.log( result );
        this.toggle_loading();
        store.commit( 'update_submission', result.data.refresh_token );
        // go next
        this.$router.push( { name: 'hazards' } );

      } );

      return false;

    },
    update_field: function( payload )  {

      store.commit( 'update_field', payload )

    },

  },
  mounted() {

    store.commit( 'set_step', 1 );

  }
}
</script>

<style scoped>

</style>