<template>
  <div class="form_item input_file">
    <Form_Label v-bind:name="name" v-bind:label="label" v-bind:info="info"/>
    <div class="input">
      <File_Upload v-bind:file="file" v-bind:index="index" v-bind:field="file_field" @uploaded="uploaded" />
    </div>
  </div>
</template>

<script>
import Form_Label from "@/components/Form_Label";
import File_Upload from "@/components/File_Upload";

export default {
  name: "Input_File",
  components: {File_Upload, Form_Label },
  props: {
    label: String,
    name: String,
    value: Object,
    index: Number,
    file: [ Object, Boolean ],
    file_field: Object,
    info: String,
    store: String
  },
  methods: {
    changed : function( value ) {
      this.$emit( 'changed', {
        store: this.store,
        name: this.name,
        value: value
      } );
    },
    uploaded : function( value )  {

      this.$emit( 'uploaded', {
        store: this.store,
        file_field: this.file_field,
        value: value,
      } );

    }
  }
}
</script>

<style scoped>

</style>