import { render, staticRenderFns } from "./Personal_Details.vue?vue&type=template&id=9544b96e&scoped=true&"
import script from "./Personal_Details.vue?vue&type=script&lang=js&"
export * from "./Personal_Details.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9544b96e",
  null
  
)

export default component.exports