<template>
  <div>
    <input type="file" :name="upload_field_name" :disabled="is_saving" @change="files_change( $event.target.name, $event.target.files );"
           accept="*" class="input_file_button"/>
    <p v-if="( is_initial && file ) || is_success">Uploaded file: <a v-bind:href="file.url" target="_blank">{{file.filename}}</a></p>
    <p v-if="is_saving">Uploading file...</p>
    <p v-if="is_success">File successfully uploaded</p>
    <!--p v-html="file"></p>
    <p v-html="field"></p>
    <p v-html="index"></p-->
  </div>
</template>

<script>

import store from "@/store/store";

const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
export default {
  name: "File_Upload",
  props: {
    field: Object,
    index: Number,
    file: [ Object, Boolean ]
  },
  data()  {

    return {
      upload_error : null,
      current_status: null,
      upload_field_name: 'upload_file'
    }

  },
  computed: {

    is_initial()  {

      return this.current_status === STATUS_INITIAL;

    },
    is_saving() {

      return this.current_status === STATUS_SAVING;

    },
    is_success()  {

      return this.current_status === STATUS_SUCCESS;

    },
    is_failed() {

      return this.current_status === STATUS_FAILED;

    }
  },
  methods: {
    reset() {

      this.current_status = STATUS_INITIAL;
      this.upload_error = null;

    },
    save( form_data )  {

      this.current_status = STATUS_SAVING;

      store.dispatch( 'upload', form_data )
        .then( x => {

          console.log('finished', x.file, x );
          this.$emit( 'uploaded', x.file );
          this.current_status = STATUS_SUCCESS;

        } )
        .catch( err => {

          this.upload_error = err.response;
          this.current_status = STATUS_FAILED;

        } );

    },
    files_change( field_name, file_list ) {

      let parent_key = null;

      if( typeof this.field.parent_field !== "undefined" )  {

        parent_key = this.field.parent_field.key;

      }

      const form_data = new FormData();
      form_data.append( 'key', this.field.key );
      form_data.append( 'parent_key', parent_key );
      form_data.append( 'index', this.index );

      if( !file_list.length ) return;

      Array
        .from( Array( file_list.length ).keys() )
        .map( x => {

          form_data.append( field_name, file_list[ x ], file_list[ x ].name );

        } );

      this.save( form_data );

    }
  },
  mounted() {

    this.reset();

  }
}
</script>

<style scoped>

</style>